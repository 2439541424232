









































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";
import { RoadLink } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";


@Component({
    components: {
        pagination,
    }
})
export default class RoadLinkListView extends Vue {
    qUsers: RoadLink[] = [];

    async created(){
      await metaStore.getRoadLink();
    }

    get AdminRouter() {
        return AdminRouter;
    }

    get roadLinkList() {
        return metaStore.roadLinkList;
    }
} 
